import { createSlice } from "@reduxjs/toolkit";

const chatDataSlice = createSlice({
  name: "chatData",
  initialState: {
    isOnline: false,
    chatsData: [],
  },
  reducers: {
    setChats: (state, action) => {
      state.chatsData = action.payload;
    },
    addMessage: (state, action) => {
      // console.log("add msg",action.payload);

        const chat = state.chatsData.find(chat => chat.chatId === action.payload.id);
        if (chat) {
            chat.messages.push(action.payload.formattedMsg); // Add the message to the correct chat's messages array
        } else {
            // Handle case where chatId doesn't exist
            console.error(`Chat with id ${action.payload.id} not found.`);
        }
    },
    updateMsg: (state, action) => {
      // console.log("update msg", action.payload);
    
      const chat = state.chatsData.find(chat => chat.chatId === action.payload.chatId);
      if (chat) {
        const msg = chat.messages.find(msg => msg.messageId === action.payload.messageId);
        if (msg) {
          // Properly assign the status
          msg.status = action.payload.status;
        } else {
          console.error(`Msg with id ${action.payload.messageId} not found.`);
        }
      } else {
        // Handle case where chatId doesn't exist
        console.error(`Chat with id ${action.payload.chatId} not found.`);
      }
    },
    
  },
});

// Export both actions
export const { setChats, addMessage, updateMsg } = chatDataSlice.actions;
export default chatDataSlice.reducer;
