import { AccountCircle, Add, AssistantPhotoOutlined, BugReport, Chat, EmojiPeople, Explore, GridView, Home, MenuBookTwoTone, More, Search } from '@mui/icons-material';
import { AppBar, Box, Button, Fab, IconButton, Menu, MenuItem, styled, Toolbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';


const Appbar_Bottom = () => {
  const [selectedTab, setSelectedTab] = useState("/");
  const location = useLocation();
  const navigate = useNavigate()
  const handleNavigation = (route) => {
    setSelectedTab(route)
    navigate(route)

  }
  const style = {
    tab : {
      display: "flex", 
      flexDirection: "column", 
      width: "33%", 
      textTransform:"none", 
      justifyContent:"center" ,
      color: "#708ad4"
    },
    selectedTab:{
      display: "flex", 
      flexDirection: "column", 
      width: "33%", 
      textTransform:"none", 
      justifyContent:"center" ,
      backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
      color:"white"
    }
  }

  const menuData =
    [
      { label: "Home", icon: <Home sx={{ marginRight: 1,  }} />, newRoute: '/' },
      { label: "Chats", icon: <Chat sx={{ marginRight: 1 }} />, newRoute: 'chats' },
      { label: "Profile", icon: <EmojiPeople sx={{ marginRight: 1 }} />, newRoute: 'profile' },
    ]
  
  useEffect(()=> {
    const firstWord = location.pathname === '/' ? '/' : location.pathname.split('/')[1];
    setSelectedTab(firstWord)
    // console.log(firstWord);
    
  },[location])
  return (
    <AppBar position="fixed" 
  sx={{ 
    top: 'auto', 
    bottom: 0, 
    backgroundColor: "#fff", 
    display: "flex", 
    flexDirection: "row", 
    width: "100%", // Ensure it takes full width up to 500px
    maxWidth: 500, // Maximum width limit
    margin: "0 auto", // Center the AppBar when maxWidth is reached
    left: 0, // Ensure it's aligned properly within the viewport
    right: 0, // Align right to the viewport to center it
  }}>
    {menuData.map((menu) => {
            return <Button  
                    sx={menu.newRoute === selectedTab ? style.selectedTab : style.tab
                     } 
                    onClick={() => { handleNavigation(menu.newRoute) }}>
              <Typography  >{menu.icon}</Typography>
              <Typography  >{menu.label}</Typography>
            </Button>
          })}
    </AppBar>
  );
};


export default Appbar_Bottom;
