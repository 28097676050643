import { createSlice } from "@reduxjs/toolkit";


const buyersSlice = createSlice({
    name: "buyer",
    initialState : null,
    reducers: {
        setBuyer: (state, action) => {
            // console.log(state);
            return state = action.payload;
        },
        updateBuyer: (state, action) => {
            // if (action !== undefined) {
            //     state.name = action.payload.name
            //     state.email = action.payload.email
            // }
            // console.log(action);
        }
    },
});

export const { setBuyer, updateBuyer } = buyersSlice.actions;
export default buyersSlice.reducer;
