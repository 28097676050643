import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";
import Welcome from "../inc/Welcome";
import Toolbar from "@mui/material/Toolbar";
import { Box, Button, Snackbar } from "@mui/material";
import AppbarTop from "../inc/Appbar_Top";
import Appbar_Bottom from "../inc/Appbar_Bottom";
import { useDispatch, useSelector } from "react-redux";
import { useCreateBuyerMutation, useGetBuyerByIdQuery, useGetPartnerBusinessDataQuery, useGetSquadByIdQuery, useGetSquadDealsBySquadIdQuery } from "../Store/api/apiSlice";
import { setBuyer, updateBuyer } from "../Store/slices/buyersSlice";
import { setSquad } from "../Store/slices/squadsSlice"; // Ensure this path is correct
import { setSquadDeals } from "../Store/slices/dealSlice";
import LoadingAnimation from "../inc/LoadingAnimation";
import { changeSwipeComponentStatus } from "../Store/slices/loadingSlice";
import { setSnackbar } from "../Store/slices/snackbarSlice";
import { setBusinessData } from "../Store/slices/businessDataSlice";
import ErrorAnimation from "../inc/ErrorAnimation";

const Home = () => {
  const { isAuthenticated, isLoading: authLoading, user } = useAuth0();
  const dispatch = useDispatch();
  const isSwipeCompnent = useSelector(state => state.loadingData.isSwipeCompnent)
  const snackBarData = useSelector(state => state.snackbar)

  // Squad state management
  const [squadId, setSquadId] = useState(null);
  const [partnerEmail, setPartnerEmail] = useState(null);
  const [isSnackBarData, setSnackBarData] = useState(Object)


  //User email id
  // Load buyer data
  const {
    data: buyerData,
    error: buyerError,
    isLoading: buyerLoading
  } = useGetBuyerByIdQuery(user?.email, { skip: !user?.email });

  // Mutation hook to create a new buyer
  const [createBuyer, { data: createdUserData, error: createError, isLoading: createLoading }] = useCreateBuyerMutation();

  // Load squad data only after squadId is set
  const {
    data: squadData,
    error: squadError,
    isLoading: squadLoading
  } = useGetSquadByIdQuery(squadId, { skip: !squadId });

  const {
    data: squadDealsData,
    error: squadDealsError,
    isLoading: squadDealsLoading
  } = useGetSquadDealsBySquadIdQuery(squadId, { skip: !squadId });

  const {
    data: partnerBusinessData,
    error: partnerBusinessError,
    isLoading: partnerBusinessLoading
  } = useGetPartnerBusinessDataQuery(String(partnerEmail), { skip: !partnerEmail });

  useEffect(()=>{
    if(squadData?.agent){
      const email = String(squadData?.agent?.agentId)
      setPartnerEmail(email)      
    }
  },[squadData])

  useEffect(()=>{
    if(partnerBusinessData){
      dispatch(setBusinessData(partnerBusinessData))
    }
  },[partnerBusinessData])
  useEffect(() => {
    if (createdUserData) {
      dispatch(setBuyer(createdUserData));
      setSquadId(createdUserData.squadId); // Set squadId from created buyer data
     
    }
  }, [createdUserData]);

  useEffect(() => {
    if (!squadLoading && squadData) {
      dispatch(setSquad(squadData));
    }
  }, [squadData, squadLoading, dispatch]);

  useEffect(() => {
    if (!squadDealsLoading && squadDealsData) {
      dispatch(setSquadDeals(squadDealsData));
    }
  }, [squadDealsData, squadDealsLoading, dispatch]);

useEffect(()=>{
  if ( buyerData) {
    dispatch(setBuyer(buyerData));
    setSquadId(buyerData.squadId); // Set squadId from buyer data
    // console.log(buyerData);
    
  } 
},[buyerData])
  //Push Data in redux
  useEffect(() => {
    // console.log(buyerError);
    // dispatch(setSnackbar(
    //   {
    //     message: buyerError?.data?.detail || "New Account" ,
    //     isOpen: true,
    //     action : "Close"
    // }
    // ))
   if (buyerError?.status === 404 && !buyerData) {
  
      dispatch(updateBuyer({ name: user?.name, email: user?.email }))
      if (user?.email !== undefined && !createError) {
        const buyerSchema = {
          "userId": user?.email,
          "squadId": undefined,
          "username": undefined,
          "password": undefined,
          "email": user?.email|| undefined,
          "mobile": undefined,
          "name": user?.name || undefined,
          "dob": undefined,
          "dobPretty": undefined,
          "gender": undefined,
          "maritalStatus": undefined,
          "profession": undefined,
          "annualIncome": undefined,
          "incomeRange": {
              "min": undefined,
              "max": undefined
          },
          "preferredPropertyType": undefined,
          "preferredLocation": undefined,
          "budget": {
              "min": undefined,
              "max": undefined
          },
          "currentStatus": undefined,
          "avatarImg": undefined,
          "address": {
              "addressLine": undefined,
              "city": undefined,
              "pincode": undefined,
              "stateCode": undefined,
              "state": undefined,
              "country": undefined
          },
          "familyDetails": {
              "motherName": undefined,
              "fatherName": undefined,
              "spouseName": undefined,
              "numberOfKids": undefined
          },
          "propertyPreferences": {
              "propertyType": undefined,
              "locationPreferences": undefined,
              "amenities": undefined,
              "otherPreferences": undefined
          },
          "preferences": {
              "likeMindedPeople": undefined,
              "incomeRangePreference": {
                  "min": undefined,
                  "max": undefined
              }
          },
          "membership": {
              "membershipId": undefined,
              "membershipType": undefined,
              "startDate": undefined,
              "endDate": undefined,
              "price": undefined
          },
          "createdOn": undefined,
          "lastLogin": undefined
      }
        createBuyer(buyerSchema)      
      }
    }
  }, [buyerError]);


  const handleClose = () => {
    dispatch(setSnackbar(
      {
        message: "",
        isOpen: false,
        action: ""
      }
    ))
    // setSnackBarOPen(true)
  }
  useEffect(()=>{
    setSnackBarData(snackBarData.data)    
  },[snackBarData])



  return (
    <div style={{ height: "100%" }}>
      {authLoading ? (
        <LoadingAnimation />
      ) :
      isAuthenticated ? (
        <Box sx={{ display: "flex" }}>
            <AppbarTop />
            {buyerLoading ?
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%", alignContent: "center", alignItems: "center", height: 500 }}>
                <LoadingAnimation />
              </Box> :
              createError ? <ErrorAnimation /> :
              <Box component="main" sx={{  flexGrow: 1, p: 1, marginBottom: 15 }}>
                <Toolbar />
                <Snackbar
                  sx={{ position: "absolute", bottom: 20 }}
                  open={isSnackBarData?.isOpen}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  message={isSnackBarData?.message}
                  action={isSnackBarData?.action}
                />
                <Outlet />
              </Box>}

            {!isSwipeCompnent && <Appbar_Bottom />}
          </Box>
      ) : (
        <Welcome />
      )}

    </div>
  );
};

export default Home;
