import { ArrowDownward, Error, Home, HouseSiding, LocationCity, LocationOn, Money, Psychology, Savings, VolumeDown, VolumeUp } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Menu, Slider, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useUpdateBuyerMutation } from '../Store/api/apiSlice';
import { useSelector } from 'react-redux';
import LoadingAnimation from './LoadingAnimation';
import ErrorAnimation from './ErrorAnimation';
import { LoadingButton } from '@mui/lab';

const Preferences = () => {
    const [updateBuyer, { isLoading, data, isSuccess, isError }] = useUpdateBuyerMutation()
    const buyer = useSelector(state => state.buyer)
    const [buyerDataLocal, setBuyerDataLocal] = useState(null)
    const [location, setLocation] = useState(null);
    const [propertyType, setPropertyType] = React.useState("Apartment");
    const [value, setValue] = React.useState([60, 80]);
    const [tabValue, settabValue] = React.useState(0);
    const options = ["Pune", "Mumbai", "Latur"]
    const marks = [
        {
          value: 40,
          label: '40',
        },
        {
          value: 60,
          label: '60',
        },
        {
          value: 80,
          label: '80',
        },
        {
          value: 100,
          label: '1 cr',
        },
        {
          value: 120,
          label: '1.2',
        },
        {
          value: 140,
          label: '1.4',
        },
      ];
    const handleTabChange = (event, newValue) => {
        settabValue(newValue);
        if (newValue === 0) {
            setPropertyType("Apartment")
        } else {
            setPropertyType("Row House")
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        //   console.log(newValue);

    };
    useEffect(() => {
        setBuyerDataLocal(buyer)
        setLocation(buyer?.preferredLocation)
        // console.log(buyer);

    }, [buyer])
    function valuetext(value) {
        return `${value}°C`;
    }
    const handleSubmit = () => {
        const body = {
            preferredLocation: location,
            preferredPropertyType: propertyType,
            budget: {
                min: value[0],
                max: value[1]
            }
        }
        const id = buyerDataLocal?.id || null
        if (id) {
            updateBuyer({ id, body })
        }
    }
    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            pt: 2,
            maxWidth: 500,
            margin: "auto"
        }}>
            <Box>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="icon tabs example">
                    <Tab icon={<LocationCity />} aria-label="phone" label="Apartment" />
                    <Tab icon={<HouseSiding />} aria-label="person" label="Row House" />
                </Tabs>
            </Box>
            <Box sx={{
                width: "70%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }} >
                <Typography variant='body1' sx={{ color: "#708ad4" }}>Budget(lakh)</Typography>
                <Slider
                    getAriaLabel={() => 'Temperature range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    marks={marks}
                    min={40}
                    max={150}
                    step={5}
                />
            </Box>

            <Box
                sx={{ width: "80%", }}
            >
                <Autocomplete
                    value={location}
                    onChange={(e, newValue) => { setLocation(newValue) }}
                    options={options}
                    renderInput={(params) => <TextField {...params} label="Location" />}
                />
            </Box>
            {
                isLoading ? <LoadingButton loading={true} /> :
                    isError ? <Typography sx={{color:"red"}}>Error Updating</Typography> :
                        isSuccess ? <Typography sx={{color:"green"}}>Updated !</Typography> :
                            <Button
                                onClick={handleSubmit}
                                sx={{
                                    backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                                    textTransform: "none",
                                    color: "white",
                                    width: "50%",
                                    maxWidth: 150
                                }}>
                                Update
                            </Button>
            }
        </Box>
    );
};

export default Preferences;