import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Paper, Rating } from "@mui/material";
import { flexbox } from "@mui/system";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { Add, CurrencyRupee, LocationOn, Home, Bathtub, SquareFoot, Discount, LockClock } from "@mui/icons-material";
import { RWebShare } from "react-web-share";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useDispatch, useSelector } from "react-redux";

const Properties = () => {
  const [propertyData, setPropertyData] = useState([]);
  const [value, setValue] = useState("expiring");
  const navigate = useNavigate();
  const location = useLocation();
  const selector = useSelector((state) => state.squadDeals)
  const buyer = useSelector((state) => state.buyer)
  const [tabDataUpdated, setTabDataUpdated] = useState([])
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "expiring") {
      setTabDataUpdated(selector.filter((property) => { return property.expiryDays <= 7 }))
      console.log(tabDataUpdated);
    } else if (newValue === "new") {
      setTabDataUpdated(selector.filter((property) => { return property.isNew === true }))
    } else if (newValue === "recommended") {
      setTabDataUpdated(selector.filter((property) => { return property.isRecommended === true }))
    }
  };

  const handleClick = (id) => {
    navigate(id);
  };

  return (
    <div>
      {buyer.squadId === null || buyer.squadId === undefined ?
        <Box m={2}>
          <Paper sx={{p:2, mb:3, textAlign:"center"}}>
            <Typography  variant="h6" >You have not joined squad yet.</Typography>
            <Typography variant='body1' >Explore the Individual deals.</Typography>
          </Paper>
          <Box display="flex" justifyContent="center">
            <Tabs
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              value={value}
              onChange={handleChange}
            >
              <Tab value="new" label="New" />
              <Tab value="expiring" label="Expiring" />
              <Tab value="recommended" label="Recommended" />
            </Tabs>
          </Box>
          <Grid
            container
            direction="row"
            spacing={3}
            pt={2}
            justifyContent="center"
          >
            {tabDataUpdated
              .map((property) => (
                <Grid item key={property?.dealId}>
                  <Card sx={{ width: "100%", maxWidth: "350px" }} key={property?.projectName} >
                    <CardActionArea
                      onClick={() => {
                        handleClick(property?.dealId);
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        height="100"
                        className="alignImg"
                        image={property?.thumbnailUrl}
                      ></CardMedia>
                    </CardActionArea>
                    <CardContent
                      sx={{ display: flexbox, justifyContent: "center" }}
                    >
                      <Typography variant="h6" sx={{ paddingBottom: 2 }}>
                        {property?.projectName}{" "}
                      </Typography>
                      <Rating defaultValue={3} readOnly></Rating>
                      <List>
                        <ListItem disablePadding>
                          <ListItemText primary={property.description} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary={"Location - " + property?.location?.addressLine} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <Home />
                          </ListItemIcon>
                          <ListItemText primary={"Type - " + property?.propertyTypes[0]?.type} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <Bathtub />
                          </ListItemIcon>
                          <ListItemText primary={"Bathrooms - " + property?.propertyTypes[0]?.units[0]?.bathrooms} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <SquareFoot />
                          </ListItemIcon>
                          <ListItemText primary={"Area - " + property?.propertyTypes[0]?.units[0]?.area?.value + " sqft"} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <CurrencyRupee />
                          </ListItemIcon>
                          <ListItemText primary={"Price Range - " + property?.minPrice + " - " + property?.maxPrice} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <Discount />
                          </ListItemIcon>
                          <ListItemText primary={"Discount Offered - " + property?.groupDiscountOffered} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <LockClock />
                          </ListItemIcon>
                          <ListItemText primary={"Days Remaining - " + String(property?.expiryDays)} />
                        </ListItem>
                      </List>
                    </CardContent>
                    <CardActions>
                      <Button onClick={() => { handleClick(property.dealId) }}>
                        Details
                      </Button>
                      <RWebShare
                        data={{
                          text: property.description,
                          url: location.pathname + "/" + property.dealId,
                          title: property.title,
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <Button size="small">
                          Share
                        </Button>
                      </RWebShare>
                      <Button>
                        <Add /> Shortlist
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box> :

        <Box m={2}>
          <Box display="flex" justifyContent="center">
            <Tabs
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              value={value}
              onChange={handleChange}
            >
              <Tab value="new" label="New" />
              <Tab value="expiring" label="Expiring" />
              <Tab value="recommended" label="Recommended" />
            </Tabs>
          </Box>
          <Grid
            container
            direction="row"
            spacing={3}
            pt={2}
            justifyContent="center"
          >
            {tabDataUpdated
              .map((property) => (
                <Grid item key={property?.dealId}>
                  <Card sx={{ width: "100%", maxWidth: "350px" }} key={property?.projectName} >
                    <CardActionArea
                      onClick={() => {
                        handleClick(property?.dealId);
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        height="100"
                        className="alignImg"
                        image={property?.thumbnailUrl}
                      ></CardMedia>
                    </CardActionArea>
                    <CardContent
                      sx={{ display: flexbox, justifyContent: "center" }}
                    >
                      <Typography variant="h6" sx={{ paddingBottom: 2 }}>
                        {property?.projectName}{" "}
                      </Typography>
                      <Rating defaultValue={3} readOnly></Rating>
                      <List>
                        <ListItem disablePadding>
                          <ListItemText primary={property.description} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary={"Location - " + property?.location?.addressLine} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <Home />
                          </ListItemIcon>
                          <ListItemText primary={"Type - " + property?.propertyTypes[0]?.type} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <Bathtub />
                          </ListItemIcon>
                          <ListItemText primary={"Bathrooms - " + property?.propertyTypes[0]?.units[0]?.bathrooms} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <SquareFoot />
                          </ListItemIcon>
                          <ListItemText primary={"Area - " + property?.propertyTypes[0]?.units[0]?.area?.value + " sqft"} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <CurrencyRupee />
                          </ListItemIcon>
                          <ListItemText primary={"Price Range - " + property?.minPrice + " - " + property?.maxPrice} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <Discount />
                          </ListItemIcon>
                          <ListItemText primary={"Discount Offered - " + property?.groupDiscountOffered} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <LockClock />
                          </ListItemIcon>
                          <ListItemText primary={"Days Remaining - " + String(property?.expiryDays)} />
                        </ListItem>
                      </List>
                    </CardContent>
                    <CardActions>
                      <Button onClick={() => { handleClick(property.dealId) }}>
                        Details
                      </Button>
                      <RWebShare
                        data={{
                          text: property.description,
                          url: location.pathname + "/" + property.dealId,
                          title: property.title,
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <Button size="small">
                          Share
                        </Button>
                      </RWebShare>
                      <Button>
                        <Add /> Shortlist
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      }
    </div>
  );
};

export default Properties;
