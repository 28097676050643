import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  AccessTime,
  CurrencyRupee,
  LocationOn,
  Home,
  Bathtub,
  SquareFoot,
  ExpandMore,
  Verified,
  Done,
} from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import PropertyImageList from "../inc/PropertyImageList";
import { useSelector } from "react-redux";

const Property = () => {
  const { user } = useAuth0();
  const [propertyData, setPropertyData] = useState(null);
  const [demoData, setDemoData] = useState(null);
  const [ifAdmitted, setIfAdmitted] = useState(false);
  const [mobile, setMobile] = useState("");
  const [ifLoading, setIfLoading] = useState(false);
  const selector = useSelector(state => state.squadDeals)
  const {id} = useParams()
  useEffect(() => {
    setPropertyData({
      projectId: "proj12345",
      projectName: "Green Meadows",
      builderName: "EcoBuild Ltd.",
      description: "A modern residential project offering luxurious apartments with sustainable living features.",
      thumbnailUrl: "https://is1-3.housingcdn.com/4f2250e8/daf980cdc4f49ff0f8aad80ea9d1ce20/v0/fs/pune_emerald_bay_building_10-mundhwa-pune-pune_projects_llp.jpeg",
      ratings: 4.5,
      minPrice: 600000,
      maxPrice: 1200000,
      isNew: true,
      isRecommended: true,
      expiryDays: 30,
      groupDiscountOffered: 75000,
      location: {
        addressLine: "123 Greenway Ave",
        city: "Springfield",
        pincode: 123456,
        stateCode: "SP",
        state: "Springfield",
        country: "India",
        latitude: 19.076,
        longitude: 72.8777
      },
      propertyTypes: [
        {
          type: "Apartment",
          units: [
            {
              unitId: "unit001",
              name: "2BHK Deluxe",
              price: 850000,
              area: {
                unit: "sqft",
                value: 1200
              },
              bedrooms: 2,
              bathrooms: 2,
              amenities: [
                "Gym",
                "Swimming Pool",
                "Clubhouse"
              ],
              images: [
                {
                  imageUrl: "/logo.png",
                  description: "Living room view"
                }
              ]
            },
            {
              unitId: "unit002",
              name: "3BHK Premium",
              price: 1100000,
              area: {
                unit: "sqft",
                value: 1600
              },
              bedrooms: 3,
              bathrooms: 3,
              amenities: [
                "Gym",
                "Swimming Pool",
                "Clubhouse",
                "Private Garden"
              ],
              images: [
                {
                  imageUrl: "https://example.com/unit002.jpg",
                  description: "Master bedroom"
                }
              ]
            }
          ]
        }
      ],
      projectStatus: "Under Construction",
      completionDate: "2025-12-01",
      createdOn: "2024-01-15",
      lastUpdated: "2024-07-20",
      contactDetails: {
        agentName: "Rajesh Kumar",
        agentEmail: "rajesh.kumar@example.com",
        agentMobile: "+91-9876543210"
      },
      videoUrl: "https://www.youtube.com/embed/dQw4w9WgXcQ" // Example YouTube video URL
    });
    console.log(id);
  }, []);

  const handleDemo = () => {
    setIfLoading(true);
    axios.post("https://squad10-backend.onrender.com/courses/demo", demoData)
      .then(() => {
        setIfLoading(false);
        setIfAdmitted(true);
      })
      .catch(() => {
        setIfAdmitted(false);
        setIfLoading(false);
      });
  };

  return (
    <>
      { selector.filter(deal=>deal?.dealId === id).map((deal)=>{
        return <Grid container spacing={5} p={2} pl={8} pt={8}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} md={6}>
            <img
              src={deal.thumbnailUrl}
              alt={deal.projectName}
              style={{ width: '100%', borderRadius: 8 }}
            />
            <Typography variant="h4" gutterBottom>
              {deal.projectName}
              {deal.isRecommended && <Verified sx={{ color: "green", ml: 1 }} />}
            </Typography>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              {deal.builderName}
            </Typography>
            <Typography paragraph>{deal.description}</Typography>
            <PropertyImageList images={deal.propertyTypes.flatMap(pt => pt.units.flatMap(u => u.images))} />
            {deal.propertyTypes.map(pt => (
              <Accordion key={pt.type}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>{pt.type} Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {pt.units.map(unit => (
                      <ListItem key={unit.unitId} divider>
                        <ListItemText
                          primary={`${unit.name} - ${unit.price.toLocaleString()}`}
                          secondary={`Area: ${unit.area.value} ${unit.area.unit}, Bedrooms: ${unit.bedrooms}, Bathrooms: ${unit.bathrooms}`}
                        />
                        <ListItemIcon>
                          <SquareFoot />
                        </ListItemIcon>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Location Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <LocationOn /> {deal.location.addressLine}, {deal.location.city}, {deal.location.state}, {deal.location.country} - {deal.location.pincode}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Video Tour</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ width: '100%', position: 'relative', paddingTop: '56.25%' }}>
                  <iframe
                    src={deal.videoUrl}
                    title="YouTube video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Plan Site Visit</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form>
                  <List>
                    <ListItem>
                      <TextField
                        variant="outlined"
                        label="Mobile Number"
                        type="text"
                        value={mobile}
                        onChange={(event) => setMobile(event.target.value)}
                        fullWidth
                      />
                    </ListItem>
                    <ListItem>
                      {ifAdmitted ? (
                        ifLoading ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <Typography variant="body1">Submitted</Typography>
                            <Done style={{ padding: 2 }} />
                          </>
                        )
                      ) : (
                        <Button onClick={handleDemo} variant="contained" color="primary">
                          Submit
                        </Button>
                      )}
                    </ListItem>
                  </List>
                </form>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Project Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  <strong>Status:</strong> {deal.projectStatus}
                </Typography>
                <Typography paragraph>
                  <strong>Completion Date:</strong> {new Date(deal.completionDate).toDateString()}
                </Typography>
                <Typography paragraph>
                  <strong>Created On:</strong> {new Date(deal.createdOn).toDateString()}
                </Typography>
                <Typography paragraph>
                  <strong>Last Updated:</strong> {new Date(deal.lastUpdated).toDateString()}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Contact Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  <strong>Agent Name:</strong> {deal?.contactDetails?.agentName}
                </Typography>
                <Typography paragraph>
                  <strong>Email:</strong> {deal?.contactDetails?.agentEmail}
                </Typography>
                <Typography paragraph>
                  <strong>Mobile:</strong> {deal?.contactDetails?.agentMobile}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
      })
      }
    </>
  );
};

export default Property;
