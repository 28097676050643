import { createSlice } from '@reduxjs/toolkit';

const squadsSlice = createSlice({
  name: 'squads',
  initialState: null,
  reducers: {
    setSquad: (state, action) => {
      return action.payload;  // Return the new state value
    },
  },
});

export const { setSquad } = squadsSlice.actions;
export default squadsSlice.reducer;
