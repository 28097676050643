import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAddMsgMutation } from '../Store/api/apiSlice';
import { changeChatName, changeChatOpen } from '../Store/slices/rootSlice';
import { AppBar, Autocomplete, Avatar, Box, Button, Grow, Input, Menu, MenuItem, MenuList, Modal, Paper, TextField, Typography } from '@mui/material';
import { Add, Cancel, Check, Close, DoneAll, Loop, MoreVert, Send } from '@mui/icons-material';
import CreateAreaVisit from '../inc/CreateAreaVisit';
import { addMessage, setChats, updateMsg } from '../Store/slices/chatDataSlice';
import { setSnackbar } from '../Store/slices/snackbarSlice';
import SuggestPartner from '../inc/SuggestPartner';
import ChatTopbar from '../inc/ChatTopbar';



const Chat = () => {
    const [messagesLocal, setMessages] = useState([]);
    const chatsData = useSelector(state => state.chatsData.chatsData);
    const partnerBusinessData = useSelector(state => state.businessData.data)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const buyer = useSelector(state => state.buyer)
    const dispatch = useDispatch();
    const { id } = useParams();
    const [addMsgApi, { data, isSuccess }] = useAddMsgMutation();
    const [newMsg, setNewMsg] = useState('');
    const [userIdLocal, setUserIdLocal] = useState(undefined);
    const [userNameLocal, setUserNameLocal] = useState(undefined);
    const [openModal, setOpenModal] = React.useState(false);
    const [openSuggestPartner, setOpenSuggestPartner] = React.useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const handleOpenSuggestPartner = () => setOpenSuggestPartner(true);
    
    const handleCloseSuggestPartner = () => setOpenSuggestPartner(false);
    const handleOpenSuggestDeveloper = () => {
        dispatch(setSnackbar({
            message: "Under Development",
            isOpen: true,
            action : "Close"
        }))
    }
    useEffect(() => {
        setUserIdLocal(buyer?.userId)
        setUserNameLocal(buyer?.name)
    }, [buyer])

    useEffect(() => {
        if (chatsData && id) {
            const chat = chatsData.find((chat) => chat.chatId === id);
            if (chat && Array.isArray(chat.messages)) {
                setMessages(chat.messages);
            } else {
                setMessages([]);
            }
        }
    }, [chatsData, id]);

    useEffect(() => {
        if (data?.chatsData) {
            dispatch(setChats(data?.chatsData));
        }
    }, [data])

    useEffect(() => {
        setNewMsg('')
    }, [isSuccess])


    const onSend = () => {
        console.log(id);

        if (newMsg?.trim() && id) {

            const currentTime = new Date().toUTCString()
            const messageId = String(Date.now());
            const formattedMsg = {
                messageId,
                senderId: userIdLocal,
                senderName: userNameLocal !== null ? userNameLocal : userIdLocal,  // Explicitly check for null or undefined
                text: newMsg,
                time: currentTime,
                status: "pending"
            };

            if (id) {
                addMsgApi({ chatId: id, msg: formattedMsg })
                    .unwrap().catch((err) => {
                        dispatch(setSnackbar(
                            {
                                message: err?.data?.detail[0]?.msg || "Error, Please try again",
                                isOpen: true,
                                action: "Close"
                            }));
                    });
            }
            dispatch(addMessage({ id, formattedMsg }))
        }
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box sx={{ height: "100%", maxWidth: 500, margin: "auto" }}>
            {partnerBusinessData?.email === null || partnerBusinessData?.email === undefined &&

                <Box sx={{
                    top: 75,
                    alignSelf:"center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    px: 2,
                    py: 1,
                    backgroundColor: "white",
                    color: "black",
                    alignItems: "center",
                    maxWidth:500,
                }}>
                    
                    <Button variant='outlined' onClick={handleOpenSuggestPartner} sx={{
                        textTransform: "none"
                    }}>Suggest Partner To Unlock Deals</Button>
                </Box>
            }
            {id && userIdLocal ? (
                <Box
                    sx={{ height: "100%", }}
                >
                    <Box sx={styles.chatContainer} >
                        {messagesLocal.map((message) => (
                            <Box key={message?.messageId} sx={message?.senderId === userIdLocal ? styles.rightMsgContainer : styles.msgContainer}>
                                <Box elevation={1} sx={message?.senderId === userIdLocal ? styles.msgWrapperLeft : styles.msgWrapperRight}>
                                    <Typography variant='caption'>{message?.senderName}</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                        <Typography variant='body2'>{message?.text}</Typography>
                                        <Typography sx={{ alignSelf: "flex-end" }}>

                                            {message?.status === "delivered" && <DoneAll sx={{ height: 10 }} />}
                                            {message?.status === "pending" && <Loop sx={{ height: 10 }} />}
                                            {message?.status === "sent" && <Check sx={{ height: 10 }} />}
                                            {message?.status === "read" && <DoneAll sx={{ height: 10 }} color='blue' />}
                                            <Typography variant='caption' >
                                                {new Intl.DateTimeFormat('en-IN',
                                                    {
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true,
                                                        day: '2-digit',
                                                        month: 'short',
                                                        timeZone: 'Asia/Kolkata', // Specify the timezone
                                                    }

                                                ).format(new Date(message?.time)) || ""}
                                            </Typography>



                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={styles.inputBox}>
                        <Input
                            sx={{ mr: 3, ml: 1, }}
                            fullWidth
                            value={newMsg}
                            placeholder='Type here ...'
                            onChange={(e) => { setNewMsg(e.target.value) }}
                            endAdornment={<Box sx={{ backgroundColor: "#1fc227", p: 1, m: 1, borderRadius: 20 }} onClick={onSend}><  Send sx={{ color: "white", height: 15 }} /></Box>}
                            startAdornment={<Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{ pr: 1 }}><Add /></Button>}
                        />
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}

                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',

                            }}

                        >
                            <MenuItem onClick={handleOpenModal}>Create Area Visit</MenuItem>
                            <MenuItem onClick={handleOpenSuggestPartner}>Suggest Partner</MenuItem>
                            <MenuItem onClick={handleOpenSuggestDeveloper}>Suggest Developer</MenuItem>
                        </Menu>
                    </Box>
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ display: "flex", alignItems: "end", justifyContent: "center" }}
                    >
                        <Box sx={{
                            backgroundColor: "white",
                            p: 3,
                            width: "100%",
                            maxWidth: 600,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            justifyContent: "center"
                        }}>
                        <CreateAreaVisit />
                        <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            justifyContent: "center"
                        }}
                        >
                            
                            <Button sx={{width: "50%",
                                    maxWidth: 150}} onClick={handleCloseModal} variant='outlined' >Cancel</Button></Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={openSuggestPartner}
                        onClose={handleCloseSuggestPartner}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ display: "flex", alignItems: "end", justifyContent: "center" }}
                    >
                        <Box sx={{
                            maxWidth: 500,
                            width: "100%",
                            px: 2,
                            py: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            background: "white"
                        }}>

                            <SuggestPartner />
                            <Button onClick={handleCloseSuggestPartner} variant='outlined' >Cancel</Button>
                        </Box>

                    </Modal>
                </Box>
            ) : (
                <Typography>Chat Uavailable.</Typography>
            )}
        </Box>
    );
};

const styles = {
    flexContainer: {
        height: "100%",
    },
    rightMsgContainer: {
        alignSelf: "flex-end",
        py: 1,
        minWidth: "50%",
        maxWidth: "70%"
    },
    msgContainer: {
        py: 1,
        alignSelf: "flex-start",
        minWidth: "50%",
        maxWidth: "70%"
    },
    msgWrapperLeft: {
        maxWidth: "100%",
        borderRadius: 1,
        marginVertical: 10,
        py: 1,
        px: 1,
        boxShadow: "rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;"
    },
    msgWrapperRight: {
        maxWidth: "100%",
        borderRadius: 1,
        marginVertical: 10,
        py: 1,
        px: 1,
        boxShadow: "rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;"
    },

    chatContainer: {

        height: "70%",
        display: "flex",
        flexDirection: "column",
    },
    inputBox: {

        backgroundColor: "#fff",
        width: "100%",
        maxWidth: 500,
        bottom: 60,
        position: "fixed",
        display: 'flex',
        pb: 2,

    },
}

export default Chat;
