import { createSlice } from "@reduxjs/toolkit";

const vibeSlice = createSlice({
    name : "vibeData",
    initialState : [
        { id: "1", label: "Near IT Park", color: "#1e3264", imageUrl: "/gif/vlogger.gif", shortCode: 'IT' },
        { id: "2", label: "Cricket", color: "#5f8108", imageUrl: "/gif/cricket.gif", shortCode: 'Cricket' },
        { id: "3", label: "Just Married", color: "#db148b", imageUrl: "/gif/wedding.gif" },
        { id: "4", label: "Investment", color: "#b02897", imageUrl: "/gif/financial-advisor.gif" },
        { id: "5", label: "Luxury", color: "#a56752", imageUrl: "/gif/ruby.gif" },
        { id: "6", label: "NRI", color: "#b95d06", imageUrl: "/gif/trip.gif" },
        { id: "7", label: "Eco-Conscious", color: "#1e3264", imageUrl: "/gif/green-planet.gif" },
        { id: "8", label: "Joint Family", color: "#8e66ac", imageUrl: "/gif/vlogger.gif" },
        { id: "9", label: "Gujrati", color: "#777777", imageUrl: "/gif/vlogger.gif" },
        { id: "10", label: "Jain", color: "#1e3264", imageUrl: "/gif/vlogger.gif" }
    ],
    reducers: {

    }
})

export default vibeSlice.reducer