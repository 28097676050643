import { createSlice } from "@reduxjs/toolkit";

const propertiesSlice = createSlice({
    name: "properties",
    initialState: {
        list: [],
        selectedProperty: null,
    },
    reducers: {
        setProperties: (state, action) => {
            state.list = action.payload;
        },
        selectProperty: (state, action) => {
            state.selectedProperty = action.payload;
        },
    },
});

export const { setProperties, selectProperty } = propertiesSlice.actions;
export default propertiesSlice.reducer;
