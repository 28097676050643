import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const SquadOverview = () => {
    useEffect(()=>{
        console.log("component loaded");
        
    },[])
    const {id} = useParams()
    return (
        <>
            <Typography sx={{mt:10}}>{id}</Typography>
        </>
    );
};

export default SquadOverview;