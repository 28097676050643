import { createSlice } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
    name : "loadingData",
    initialState:{
        isSwipeCompnent : false,

    },
    reducers :{
        changeSwipeComponentStatus : (state, action) => {
            state.isSwipeCompnent = action.payload
        }
    }
})
export const {changeSwipeComponentStatus} = loadingSlice.actions
export default loadingSlice.reducer