import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const validationSchema = yup.object({
  project_name: yup.string("Enter the project name").required("Project name is required"),
  expected_discount: yup.string("Enter expected discount").required("Expected discount is required"),
  property_type: yup.array("Add Type Of Property"),
});

const propertyOptions = [
  { label: "Property 1", value: "property1" },
  { label: "Property 2", value: "property2" },
  { label: "Property 3", value: "property3" },
  { label: "Not In The List ?", value: "newProject" },
  // Add more property options here
];

const SuggestProject = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const { user } = useAuth0();
  const formik = useFormik({
    initialValues: {
      project_name: "",
      expected_discount: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = {
        email: user.email,
        mobile: user.mobile,
        project_name: values.project_name,
        expected_discount: values.expected_discount,
        selected_property: selectedProperty,
      };

      axios.post("https://your-backend-endpoint.com/suggestions", formData)
        .then((response) => {
          console.log("Form submitted successfully:", response.data);
          setActiveStep(steps.length);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    },
  });

  const steps = ["Select Property", "Provide Details"];
  const [isNewProject, SetIsNewProject] = useState(false)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    formik.resetForm();
    setSelectedProperty(null);
  };

  useEffect(() => {
    if (selectedProperty?.value === "newProject") {
      SetIsNewProject(true);
    } else {
      SetIsNewProject(false);
    }
  }, [selectedProperty]);

  return (
    <Box p={5}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <Box>
          <Typography variant="h6" align="center">
            Suggestion Submitted Successfully!
          </Typography>
          <Button onClick={handleReset}>Submit Another Suggestion</Button>
        </Box>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          {activeStep === 0 && (
            <Box p={3}>
              <Autocomplete
                options={propertyOptions}
                getOptionLabel={(option) => option.label}
                value={selectedProperty}
                onChange={(event, newValue) => setSelectedProperty(newValue)}
                renderInput={(params) => <TextField {...params} label="Select Project" variant="outlined" />}
              />
              {isNewProject &&
                <TextField
                  fullWidth
                  id="project_name"
                  name="project_name"
                  label="Project Name"
                  variant="outlined"
                  value={formik.values.project_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.project_name && Boolean(formik.errors.project_name)}
                  helperText={formik.touched.project_name && formik.errors.project_name}
                  margin="normal"
                />
              }
              <Box mt={2}>
                <Button
                  disabled={!selectedProperty || (selectedProperty?.value === "newProject" && !formik.values.project_name)}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Box>
            </Box>
          )}
          {activeStep === 1 && (
            <Box p={3}>
              <TextField
                fullWidth
                id="expected_discount"
                name="expected_discount"
                label="Expected Discount in rupees"
                variant="outlined"
                value={formik.values.expected_discount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.expected_discount && Boolean(formik.errors.expected_discount)}
                helperText={formik.touched.expected_discount && formik.errors.expected_discount}
                margin="normal"
              />
              <Box mt={2}>
                <Button variant="contained" color="secondary" onClick={handleBack}>
                  Back
                </Button>
                <Button type="submit" variant="contained" color="primary" style={{ marginLeft: 10 }}>
                  Submit
                </Button>
              </Box>
            </Box>
          )}
        </form>
      )}
    </Box>
  );
};

export default SuggestProject;
