import { Avatar, Box, Card, CardContent, CardMedia, Chip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetSquadByIdQuery } from '../Store/api/apiSlice';

const SquadDetails = (id) => {
    const { data: squadData } = useGetSquadByIdQuery(id.id, {skip: id.id === null || id.id === undefined})
    useEffect(()=>{
        console.log(id);

    },[])
    return (
        <Box sx={{
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            maxWidth:500,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
        }}>
            {
                squadData?.agent?.agentId !== null && squadData?.agent?.agentId !== undefined &&
                <Box sx={{ textAlign: "center", display: "flex",
                    flexDirection: "row", alignItems:"center", p:2, alignSelf:"flex-end", gap:1, position:"absolute", top:0 }}>
                    <Avatar alt={squadData?.agent?.agentName} src={squadData?.agent?.agentImg}/>
                    <Box sx={{display: "flex",
                    flexDirection: "column", alignItems:"center",}}>
                    <Typography variant='subtitle1' >{squadData?.agent?.agentName || "agent name"}</Typography>
                    <Typography variant='caption'>Partner</Typography>
                    </Box>
                </Box>
            }
            {
                squadData?.squadName !== null && squadData?.squadName !== undefined &&
                <Typography variant='h5'>{squadData?.squadName || "SquadName"}</Typography>
            }
            {
                squadData?.members && <Typography variant='caption'>Total Members : {squadData?.members?.length}</Typography>
            }
            
            {
                squadData?.vibes &&
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "auto",   // Enable horizontal scroll
                    whiteSpace: "nowrap", // Prevent wrapping of items
                    marginTop: 2,
                    gap: 2,
                    width:"75%",
                    alignSelf:"center",
                    pb: 1,
                    pt:2,
                    
                    scrollSnapType: "x mandatory", // Optional: Enable scroll snapping
                    "&::-webkit-scrollbar": {
                        width: "5px",
                        maxHeight: 5,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                        boxShadow: "inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5)",
                        borderRadius: "100px",
                    },
                }}>
                    {
                        squadData?.vibes?.map(
                            (vibe) => {
                                return <Box key={vibe} sx={{display:"flex", flexDirection:"column",alignItems:"center", gap :2}}>
                                    <Avatar alt={vibe} src={vibe?.icon} />
                                    <Typography variant='subtitle1' >{vibe}</Typography>
                                </Box>
                            }
                        )
                    }
                </Box>
            }
            
            {
                squadData?.projectsShortlisted && <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "75%",
                        overflowX: "auto",   // Enable horizontal scroll
                        whiteSpace: "nowrap", // Prevent wrapping of items
                        marginTop: 2,
                        gap: 2,
                        pb: 1,
                        scrollSnapType: "x mandatory", // Optional: Enable scroll snapping
                        "&::-webkit-scrollbar": {
                            width: "5px",
                            maxHeight: 5,
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                            boxShadow: "inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5)",
                            borderRadius: "100px",
                        },
                    }}
                >

                    {
                        squadData?.projectsShortlisted?.map(
                            (project) => {
                                return <Card sx={{ minWidth: 200, textAlign:"center" }}>
                                    <CardContent>
                                        <Typography variant='body1' >{project?.projectName}</Typography>
                                        <Typography variant='caption' >{project?.location}</Typography>
                                    </CardContent>
                                </Card>
                            }
                        )
                    }
                </Box>
            }
        </Box>
    );
};

export default SquadDetails;