import { Autocomplete, Avatar, Box, Button, Modal, Paper, Rating, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAddPartnerInSuggestPartnerListMutation, useAddTimelineMutation, useSearchPartnerQuery } from '../Store/api/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoadingAnimation from './LoadingAnimation'
import ErrorAnimation from './ErrorAnimation'
import { setSnackbar } from '../Store/slices/snackbarSlice';
const SuggestPartner = () => {
    const [partnerSearchString, setPartnerSearchString] = useState(null)
    const [selectedVideo, setSelectedVideo] = useState(null)
    const buyer = useSelector(state => state.buyer)
    const [openVideo, setOpenVideo] = useState(false)
    const dispatch = useDispatch()
    const [addPartnerInSuggestPartnerList,
        {
            isError: isAddPartnerError,
            isLoading: isAddPartnerLoading,
            isSuccess: isSucessAddPartner,
            data: addPartnerData
        }] = useAddPartnerInSuggestPartnerListMutation()
    const [addSquadTimeline,

    ] = useAddTimelineMutation()
    const squad = useSelector(state => state.squad)
    const [triggerSearchApi, setTriggerSearchApi] = useState(false)
    const {
        data,
        isLoading: isSearchLoading,
        isSuccess: isSearchSucess,
        error: isSearchError
    } = useSearchPartnerQuery(partnerSearchString, {
        skip: !triggerSearchApi || partnerSearchString === null
    })
    const [partnerList, setPartnerList] = useState([])
    const [suggestedPartnerList, setSuggestedPartnerList] = useState([
        {
            "userId": null,
            "username": null,
            "password": null,
            "email": "sumit@squad10.in",
            "mobile": null,
            "name": "Sumit ",
            "dob": null,
            "gender": null,
            "profession": null,
            "aadharCardNumber": "946434669494",
            "panCard": "BAUPC4827L",
            "selectedVehicle": "Motorcycle/ Scooter",
            "selectedCity": "Mumbai",
            "selectedWorkArea": "Wakad",
            "isNewUser": false,
            "status": true,
            "address": {
                "addressLine": null,
                "city": null,
                "pincode": null,
                "stateCode": null,
                "state": null,
                "country": null
            },
            "createdOn": null,
            "lastLogin": null,
            "_id": null
        }
    ])
    useEffect(() => {
        // console.log(data);
        if (data) {
            const partnerListLocal = []
            data.map((partner) => {
                partnerListLocal.push(partner?.businessName)
            })
            setPartnerList(partnerListLocal)
        }

    }, [data])

    useEffect(() => {
        if (partnerSearchString?.length > 3) {
            setTriggerSearchApi(true)
        } else {
            setTriggerSearchApi(false)
        }
        // console.log(partnerSearchString);

    }, [partnerSearchString])

    const onSelectVideo = (url) => {
        setSelectedVideo(url)
    }

    const onClickSuggest = (partner) => {
        if (partner._id && squad?.squadId) {
            console.log("squad", squad?.squadId);
            console.log("partner", partner._id);
            const squadId = squad?.squadId
            const body = {
                agentId: partner?._id || null,
                agentName: partner?.businessName || null
            }
            addPartnerInSuggestPartnerList(
                { squadId, body }
            )

        }

    }

    // Error Handling
    useEffect(() => {
        if (isAddPartnerError) {
            dispatch(setSnackbar({
                message: "Error Suggesting Partner",
                isOpen: true,
                action: "Close"
            }))
        }
    }, [isAddPartnerError])
    useEffect(() => {
        // console.log(isSearchError);
        if (isSearchError?.status === 404) {

            dispatch(setSnackbar({
                message: isSearchError?.data?.detail || "Error",
                isOpen: true,
                action: "Close"
            }))
        }

    }, [isSearchError])
    useEffect(() => {
        if (isSucessAddPartner && addPartnerData) {

            dispatch(setSnackbar({
                message: "Partner Suggested",
                isOpen: true,
                action: "Close"
            }))
        }
    }, [isSucessAddPartner])

    //Add Timeline of Squad
    useEffect(() => {
        if (addPartnerData) {
            const randomNumber = Math.random() * 1000; // Generate a random number between 0 and 100
            const randomNumberString = Math.floor(randomNumber).toString(); // Convert to integer and then to string
            const currentTime = new Date().toISOString()
            const squadId = squad?.squadId
            const body = {
                id: randomNumberString || null,
                userId: buyer?.userId || null,
                time: currentTime || null,
                action: "New Partner Suggested by " + buyer?.userId || "New Partner Suggested" ,
                icon_id: "tour" || null,
                short_desc: "Test" || null,
            }
            if(squad?.squadId)
            addSquadTimeline({
                squadId, body
            })
        }
    }, [addPartnerData])

    return (
        <Box sx={{
            backgroundColor: "white",
            width: "100%",
            maxWidth: 500,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: 500,
            gap: 3,

            pt: 3
        }}>
            <Typography>Let's Suggest "The Perfect Partner"</Typography>
            <Autocomplete
                sx={{
                    width: "100%"
                }}
                value={partnerSearchString}
                onChange={(e, newValue) => { setPartnerSearchString(newValue) }}
                options={partnerList}
                renderInput={(params) => <TextField onChange={(e) => { setPartnerSearchString(e.target.value) }} {...params} label="Search" />}
            />
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    py: 2,
                    flexWrap: 'nowrap',  // Ensure no wrapping of items
                    overflowX: 'auto',  // Enable horizontal scrolling
                    maxWidth: '100%',  // Ensure the container does not expand beyond the viewport width
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 5px grey',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundImage: 'linear-gradient(90deg, #d0368a 0%, #708ad4 99%)',
                        boxShadow: 'inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5)',
                        borderRadius: '100px',
                    },
                }}
            >


                {
                    isAddPartnerLoading || isSearchLoading ? <LoadingAnimation /> :
                        data &&
                        data.map((partner) => {
                            return <Paper
                                key={partner?._id}
                                sx={{
                                    maxWidth: 150,
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    p: 2,
                                    textAlign: "center",
                                    gap: 1,
                                    borderRadius: 2
                                }}
                            >
                                <img src={partner?.logo || "/gif/vlogger.gif"} style={{ height: 50 }} alt="" />
                                {partner?.businessName && <Typography variant='subtitle1'>{partner?.businessName || "Name"}</Typography>}
                                {partner?.description && <Typography variant='caption' >{partner?.description || "description"}</Typography>}
                                {partner?.pitchUrl && <Button
                                    onClick={() => { onSelectVideo(partner?.pitchUrl, setOpenVideo(true)) }}
                                    sx={{ textTransform: "none" }}
                                >Watch Video</Button>}

                                <Button
                                    onClick={() => { onClickSuggest(partner) }}
                                    variant='outlined'
                                    sx={{
                                        backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                                        color: "white",
                                        width: "80%",
                                        maxWidth: 200,
                                        textTransform: "none"
                                    }}>
                                    Suggest
                                </Button>
                            </Paper>
                        })
                }
            </Box>
            {/* <Typography>Recommended</Typography>
            {suggestedPartnerList.length > 0 && suggestedPartnerList.map((partner, index) => (
                <Box
                    key={index}
                    sx={{
                        minWidth: '80px', // Set a fixed width for each item
                        py: 2,
                        px: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        sx={{ alignSelf: 'center', my: 1 }}
                        alt={partner?.name || ""}
                        src="/static/images/avatar/1.jpg"
                    />
                    <Rating
                            readOnly
                            sx={{
                                color: '#9dccc6',
                                fontSize: '0.8rem',
                                alignSelf: 'center',
                                my: 1,
                            }}
                            value={partner?.ratings}
                        />
                    <Typography variant="body2">{partner?.name}</Typography>
                </Box>
            ))} */}
            <Modal open={openVideo}
                sx={{ color: "white", }}
            >
                <Box sx={{
                    position: "absolute",
                    bottom: 10,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2
                }}>
                    {selectedVideo ?
                        <video style={{ height: 300 }} src={selectedVideo || ""}></video> :
                        <Typography>No Video Selected</Typography>

                    }
                    <Button onClick={() => { setOpenVideo(false) }} variant='contained' >Close</Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default SuggestPartner;