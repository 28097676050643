import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import propertiesSlice from "./slices/propertiesSlice";
import squadsSlice from "./slices/squadsSlice";
import buyersSlice from "./slices/buyersSlice";
import authSlice from "./slices/authSlice";
import squadDealSlice from "./slices/dealSlice"
import loadingSlice from "./slices/loadingSlice";
import snackbarSlice from "./slices/snackbarSlice";
import chatDataSlice from "./slices/chatDataSlice";
import rootSlice from "./slices/rootSlice";
import businessDataSlice from "./slices/businessDataSlice";
import vibeSlice from "./slices/vibeSlice";
const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        properties: propertiesSlice,
        squad: squadsSlice,
        buyer: buyersSlice,
        auth: authSlice,
        squadDeals :squadDealSlice,
        loadingData : loadingSlice,
        snackbar : snackbarSlice,
        chatsData : chatDataSlice,
        rootData : rootSlice,
        businessData : businessDataSlice,
        vibeData : vibeSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
