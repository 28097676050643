import { Autocomplete, Box, Button, Grow, Slide, Slider, TextField, Typography, useTheme, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateAreaVisitMutation } from '../Store/api/apiSlice';
import LoadingAnimation from './LoadingAnimation'
import { CheckCircleOutline } from '@mui/icons-material';
import ErrorAnimation from './ErrorAnimation';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { setSnackbar } from '../Store/slices/snackbarSlice';
const CreateAreaVisit = () => {
    const partnerBusinessData = useSelector(state => state.businessData.data)
    const dispatch = useDispatch()
    const squad = useSelector(state => state.squad)
    const buyer = useSelector(state => state.buyer)
    const [createAreaVisit, { isLoading, isSuccess, error }] = useCreateAreaVisitMutation()
    const [cityList, setCityList] = React.useState(["Pune"]);
    const [selectedCity, setSelectedCity] = React.useState([]);
    const [areaList, setAreaList] = React.useState(["Wakad", "Punawale"]);
    const theme = useTheme()
    const [pay, setPay] = React.useState(99);
    const [time, setTime] = React.useState(null);
    const [date, setDate] = useState(null);
    const [combinedDateTime, setCombinedDateTime] = useState(null);
    const marks = [
        {
            value: 99,
            label: '99',
        },
        {
            value: 299,
            label: '299',
        },
        {
            value: 499,
            label: '499',
        },
        {
            value: 699,
            label: '699',
        },
        {
            value: 899,
            label: '899',
        },
        {
            value: 1099,
            label: '1099',
        },
    ];
    const [selectedRoute, setSelectedRoute] = React.useState([]);
    const [durationList, setDurationList] = React.useState([
        { label: '100 min', durationInMin: 100 },
        { label: '200 min', durationInMin: 200 },
        { label: '300 min', durationInMin: 300 },
        { label: '400 min', durationInMin: 400 },
    ]);
    const [selectedDuration, setSelectedDuration] = React.useState([]);
 
    const [propertiesList, setPropertiesList] = React.useState([]);
    const [selectedProperties, setSelectedProperties] = React.useState([]);
    
    useEffect(() => {
        if (partnerBusinessData?.cities) {
            setCityList(partnerBusinessData?.cities || ["Pune"])
        }
        if (partnerBusinessData?.routes) {
            setAreaList(partnerBusinessData?.routes || ["Wakad, Punawale"])
        }
        if (partnerBusinessData?.properties) {
            partnerBusinessData?.properties.map((property) => {
                propertiesList.push({ label: property?.propertyName, ...property })
            })

        }
        // console.log(partnerBusinessData);

    }, [partnerBusinessData])

    const onCreate = () => {
        const randomNumber = Math.random() * 1000; // Generate a random number between 0 and 100
        const randomNumberString = Math.floor(randomNumber).toString(); // Convert to integer and then to string

        const body = {
            agentId: partnerBusinessData?.email,
            visitId: `${squad?.squadId}${randomNumberString}`,
            squadId: buyer?.squadId,
            buyerName: buyer?.name,
            membershipIcon: buyer?.membershipIcon,
            pay: pay,
            durationInMin: selectedDuration?.durationInMin,
            tourMap: selectedRoute,
            acceptedByAgent: false,
            properties: selectedProperties,
            participants: [{
                userId: buyer?.userId,
                username: buyer?.username,
                name: buyer?.name,
                gender: buyer?.gender,
                maritalStatus: buyer?.maritalStatus,
                profession: buyer?.profession,
                annualIncome: buyer?.annualIncome,
                incomeRange: buyer?.incomeRange,
                preferredPropertyType: buyer?.preferredPropertyType,
                preferredLocation: buyer?.preferredLocation,
                budget: buyer?.budget,
                currentStatus: buyer?.currentStatus,
                avatarImg: buyer?.avatarImg
            }],
            status: "pending",
            visitTime: combinedDateTime,
            isAgentOnline: false
        };

        console.log(body); // Log the whole number
        if(partnerBusinessData?.email){
            createAreaVisit(body)
        }else {
            dispatch(setSnackbar({
                message: "Partner Not Assigned",
                isOpen: true,
                action : "Close"
            }))
        }
    };

    useEffect(()=>{
        console.log(partnerBusinessData?.email);
        
        if(partnerBusinessData?.email === null || partnerBusinessData?.email === undefined ){
            dispatch(setSnackbar({
                message: "Please Assign Partner to create new Area Visit",
                isOpen: true,
                action : "Close"
            }))
        }
    },[])

    
    const handleChange = (event) => {
        setPay(event.target.value === '' ? 0 : Number(event.target.value));
      };
      const handleChangeDate = (newDate) => {
        setDate(newDate);
    };

    const handleChangeTime = (newTime) => {
        setTime(newTime);
    };
    useEffect(()=>{
        console.log(time);
        
    },[time])
    useEffect(()=>{
        if (date && time) {
            // Create a new Date object combining both date and time
            const combinedDate = new Date(date);
            combinedDate.setHours(time.$H); // Extract hours from the time object
            combinedDate.setMinutes(time.$M); // Extract minutes from the time object

            setCombinedDateTime(combinedDate.toUTCString())
        }
    },[date, time])
    return (

        <>
            {isLoading ?
                <Box sx={{ background: "white", height: 300, width: "100%" }}>
                    <LoadingAnimation />
                </Box> :
                isSuccess ?
                    <Box sx={{ background: "white", height: 300, width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <Zoom timeout={2000} in={true}>
                            <img
                                src="/gif/checkGreen.gif"
                                alt="Success"
                                style={{ width: 100 }}
                            />
                        </Zoom>
                        <Grow timeout={3000} in={true}><Typography variant='h6' sx={{ color: "green" }}>Visit Created</Typography></Grow>
                    </Box> :
                    error ? <Box sx={{ background: "white", height: 300, width: "100%", }}>
                        <ErrorAnimation />
                    </Box> :
                    partnerBusinessData?.email === null || partnerBusinessData?.email === undefined ?
                        <Box sx={{textAlign:"center", p:2}}>
                            <Typography variant='h6' >Partner Not Assigned </Typography>
                            <Typography>This will unlock once partner is assigned. </Typography>
                        </Box> :
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            justifyContent: "center"
                        }}>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    mb: 2,
                                    width: "50%",
                                    alignSelf: "center",
                                    borderRadius: 2
                                }}
                                id="modal-modal-title"
                                variant="subtitle1"
                                component="h2">
                                Let's Create
                            </Typography>
                            <Autocomplete
                                disablePortal
                                options={cityList}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Select City" />}
                            />
                            <Autocomplete
                                multiple
                                value={selectedRoute}
                                onChange={(event, newValue) => {
                                    setSelectedRoute(newValue);
                                }}
                                options={areaList}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Select Route" />}
                            />
                            <Autocomplete
                                disablePortal
                                value={selectedDuration}
                                onChange={(event, newValue) => {
                                    setSelectedDuration(newValue);
                                }}
                                options={durationList}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Duration" />}
                            />
                            
                            <Autocomplete
                                multiple
                                value={selectedProperties}
                                onChange={(event, newValue) => {
                                    setSelectedProperties(newValue);
                                }}
                                options={propertiesList}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Properties" />}
                            />
                             <DatePicker value={date} onChange={handleChangeDate}  label="Date" />
                             <MobileTimePicker value={time} onChange={handleChangeTime} label="Time"  />
                             
                            <Box sx={{
                                px: 6,
                                textAlign: "center"
                            }}>
                                <Typography variant='body1' >Consulting Fee (₹) </Typography>
                                <Slider
                                    getAriaLabel={() => 'Pay'}
                                    value={pay}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                    min={99}
                                    max={1099}
                                    step={100}
                                />
                            </Box>
                            <Button 
                                onClick={onCreate} 
                                variant='outlined' 
                                sx={{ 
                                    backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
                                    width: "50%",
                                    maxWidth: 250, 
                                    color: "white" ,
                                    alignSelf:"center"
                                }}>
                                    Create
                            </Button>

                        </Box>
            }
        </>

    );
};

export default CreateAreaVisit;