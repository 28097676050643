import React from 'react';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import axios from 'axios';

import Typography from "@mui/material/Typography";
import { BugReportOutlined, Done } from "@mui/icons-material";
import { useReportBugMutation } from '../../Store/api/apiSlice';
import { LoadingButton } from '@mui/lab';
const BugReport = () => {
  const [bugId, setBugId] = useState("");
  const [bugTitle, setBugTitle] = useState("");
  const [description, setDescription] = useState("");
  const [completeFormData, setCompleteFormData] = useState();
  const [creteBug, { isError, isLoading, isSuccess }] = useReportBugMutation()
  const [ifFormSubmitted, setIfFormSubmitted] = useState(false);
  const { user } = useAuth0()
  const handleSubmit = (event) => {
    creteBug(completeFormData)
  }
  useEffect(() => {
    setBugId(user.email + Math.random(2000))
  }, [])
  useEffect(() => {
    setCompleteFormData({
      bugId: bugId,
      bugTitle: bugTitle,
      description: description
    })
  }, [bugId, bugTitle, description])
  return (
    <Box mt={10}>
        <form style={{ width: "100%", textAlign: "center" }}>
          <TextField
            style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
            type="text"
            label="Bug Type"
            variant="outlined"
            value={bugTitle}
            onChange={(event) => setBugTitle(event.target.value)}
          />
          <br />
          <TextField
            style={{ width: "80%", maxWidth: "500px", margin: "5px" }}
            type="text"
            label="Description"
            variant="outlined"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
          <br />
          <Box p={1}>
            {
            isLoading ? <Box sx={{display:"flex", justifyContent:"center"}}>
              <LoadingButton loading={true} />
              <Typography >Loading</Typography>
            </Box> :
            isSuccess ?
              <Box>
                <Typography variant="body2" sx={{alignItems:"center", alignContent:"center", display:"flex", justifyContent:"center", color:"green"}}>
                  Thanks <BugReportOutlined sx={{color:'green'}} /> Reported Successfully
                </Typography>
              </Box>
              : <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundImage: 'linear-gradient(90deg, #d0368a 0%, #708ad4 99%)', 
                  color:"white", 
                  textTransform:"none", 
                  width:"50%",
                  maxWidth:200, 
                  alignSelf:"center"
                }} 
                onClick={handleSubmit}
              >
                Submit
              </Button>}
          </Box>
        </form>
      </Box>
  );
};

export default BugReport;