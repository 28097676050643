import { createSlice } from "@reduxjs/toolkit"

const businessDataSlice = createSlice({
    name :"businessData",
    initialState : {
        data :{}
    }, reducers : {
        setBusinessData : (state, action) => {
            state.data = action.payload
            // console.log(action.payload);
            
        }
    }
})

export const {setBusinessData} = businessDataSlice.actions
export default businessDataSlice.reducer