import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import SquadTimeline from "../pages/SquadTimeline";
import SquadMembers from "../pages/Squad_Members";
import JoinSquad from "../joinSquad/JoinSquad";

const Community = () => {
  const selector = useSelector((state) => state.buyer);

  return (
    <div>
      <Box
        pt={1}
        sx={{
          width: "100%", // Make sure the container takes full width
          maxWidth: "96vw", // Prevent container from growing beyond viewport width
          overflowX: "hidden", // Ensure no horizontal overflow
          textAlign: "center",
        }}
      >
        {selector?.squadId === null ? (
          <JoinSquad />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              width: "100%", // Full width for the content
              maxWidth: "95vw", // Prevent overflow
              overflowX: "hidden", // Make sure it doesn't scroll horizontally
            }}
          >
            <SquadMembers />
            <SquadTimeline />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Community;
