import { createSlice } from "@reduxjs/toolkit";

const rootSlice = createSlice ({
    name : "rootData",
    initialState : {
        selectedTab : "home",
        isChatSelected : false,
        chatName : undefined,
        isUserOnline : false
    },
    reducers : {
        changeSelectedTab : (state, action) => {
            state.selectedTab = action.payload            
        },
        changeChatOpen : (state, action) => {
            state.isChatSelected = action.payload            
        },
        changeChatName : (state, action) => {
            state.chatName = action.payload            
        },
        updateIsUerOnline : (state, action) => {
            state.isUserOnline = action.payload
        }
    }
})
export const {changeSelectedTab, changeChatOpen, changeChatName, updateIsUerOnline} = rootSlice.actions

export default rootSlice.reducer