import { Autocomplete, Box, Button, Grow, Slider, TextField, Typography, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateChatDataMutation, useCreateSquadMutation, useUpdateBuyerMutation } from '../Store/api/apiSlice';
import LoadingAnimation from './LoadingAnimation';
import ErrorAnimation from './ErrorAnimation';

const CreateSquad = () => {
    const [squadName, setSquadName] = useState(undefined)
    const [squadNameValidation, SetSquadNameValidation] = useState(false)
    const [memberMobile, setMemberMobile] = useState(undefined)
    const buyer = useSelector(state => state.buyer)
    const vibes = useSelector(state => state.vibeData)
    const [squadVibes, setSquadVibes] = useState([])
    const [vibeList, setVibeList] = useState([])
    const [value, setValue] = React.useState([60, 80]);
    const marks = [
        {
            value: 40,
            label: '40',
        },
        {
            value: 60,
            label: '60',
        },
        {
            value: 80,
            label: '80',
        },
        {
            value: 100,
            label: '1 cr',
        },
        {
            value: 120,
            label: '1.2',
        },
        {
            value: 140,
            label: '1.4',
        },
    ];
    const [createSquad,
        { isLoading, error, isSuccess, data }
    ] = useCreateSquadMutation()
    const [updateBuyer,
        {
            isLoading: updateBuyerLoading,
            error: updateBuyerError,
            isSuccess: updateBuyerSuccess,
            data: updateBuyerData
        }
    ] = useUpdateBuyerMutation()
    const [createChatData] = useCreateChatDataMutation()
    const onCreate = () => {
        const randomNumber = Math.random() * 1000; // Generate a random number between 0 and 100
        const randomNumberString = Math.floor(randomNumber).toString(); // Convert to integer and then to string
        const currentTime = new Date().toISOString()
        const body = {
            squadId: squadName + randomNumberString || null,
            squadName: squadName || null,
            squadPitchUrl: null,
            vibes: squadVibes || [],
            squadBudget: {
                min: value[0],
                max: value[1]
            },
            timeline: [
                {
                    id: randomNumberString || null,
                    userId: buyer?.userId || null,
                    time: currentTime || null,
                    action: "Squad Created" || null,
                    icon_id: "check" || null,
                    short_desc: "Test" || null,
                }
            ] || [],
            members: [
                {
                    userId: buyer?.userId || null,
                    username: buyer?.username,
                    name: buyer?.name,
                    dob: buyer?.dob,
                    dobPretty: buyer?.dobPretty,
                    gender: buyer?.gender,
                    maritalStatus: buyer?.maritalStatus,
                    profession: buyer?.profession,
                    annualIncome: buyer?.annualIncome,
                    incomeRange: buyer?.incomeRange,
                    preferredPropertyType: buyer?.preferredPropertyType,
                    preferredLocation: buyer?.preferredLocation,
                    budget: buyer?.budget,
                    currentStatus: buyer?.currentStatus,
                    avatarImg: buyer?.avatarImg,
                    propertyPreferences: buyer?.propertyPreferences,
                    preferences: buyer?.preferences,
                    isAdmin: false,
                    isChannelPartner: false,
                    subscription: null,
                    ratings: null,
                    shortBio: null,
                    createdOn: null,
                    lastLogin: null
                }
            ] || [],
            membersWaiting: [],
            agent: {
                agentId: null,
                agentName: null
            },
            suggestedAgents: [],
            projectsShortlisted: [],
            activityTimeline: [],
            createdOn: ""
        }
        // console.log(body);
        if(squadName === undefined){
            SetSquadNameValidation(true)
        } else {

            createSquad(body)
        }
    }
    useEffect(() => {
        if (buyer && buyer?.id !== undefined && data?.id !== undefined) {
            const id = String(buyer?.id);  // Use 'id' to match what `updateBuyer` expects
            const squadId = data?.id;

            const body = {
                squadId: squadId
            };
            // Correctly pass 'id' and 'body' to the mutation
            updateBuyer({ id, body })
        }
        if (buyer && buyer?.id !== undefined && data?.id !== undefined && buyer?.userId !== null) {
            const body = {
                isOnline: false,
                chatsData: [
                    {
                        chatId: data?.id,
                        chatName: squadName || data?.id || "",
                        isGroupChat: true,
                        icon: "pac-man",
                        "participants": [
                            {
                                userId: buyer?.userId,
                                userName: buyer?.name || ""
                            }
                        ],
                        "messages": []
                    }
                ]
            }

            createChatData(body)
        }
    }, [isSuccess, buyer, data]);  // Make sure `buyer` and `data` are in the dependency array
    useEffect(() => {
        if (updateBuyerSuccess) {
            const timeoutId = setTimeout(() => {
                window.location.reload();  // Reload the page after 2 seconds
            }, 2000);  // 2000 ms = 2 seconds

            // Optional: Clean up the timeout if the component unmounts or the effect is re-triggered
            return () => clearTimeout(timeoutId);
        }
    }, [updateBuyerSuccess]);

    useEffect(() => {
        if (vibes) {
            vibes.map((vibe) => {
                const data = []
                vibeList.push(vibe?.label)
                // console.log(vibe?.label);
            })
        }
    }, [vibes])
    const handleChange = (event, newValue) => {
        setValue(newValue);
        //   console.log(newValue);

    };


    return (
        <Box
            sx={{
                backgroundColor: "white",
                position: "absolute",
                width: "100%",
                maxWidth: 700,
                minHeight: 400,
                bottom: 0,
                paddingY: 5,
                display: "flex"
            }}
        >
            {isLoading ? <LoadingAnimation /> :
                error ? <ErrorAnimation /> :
                    isSuccess ? <Box
                        sx={{
                            background: "white",
                            height: 30,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <Zoom timeout={2000} in={true}>
                            <img
                                src="/gif/fireworks.gif"
                                alt="Error"
                                style={{ width: 150 }}
                            />
                        </Zoom>
                        <Grow timeout={3000} in={true}>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant='h6' sx={{ color: "green" }}>Squad Created </Typography>
                                <Typography variant='body2' sx={{ color: "green" }}>Taking You to Squad Homepage. </Typography>

                            </Box>
                        </Grow>

                    </Box> :
                        <Box sx={{
                            display: "flex",
                            gap: 2,
                            flexDirection: "column",
                            alignItems: "center",
                            alignSelf: "flex-end",
                            height: "100%",
                            width: "100%",
                            mb:1
                        }}>
                            <img height={100} src="https://firebasestorage.googleapis.com/v0/b/seller-squad-10.appspot.com/o/squadDEtails.jpg?alt=media&token=31a58a22-8911-45d3-a20a-0bac5977b9d1" alt="Narayan Murti" />
                            <TextField error={squadNameValidation} helperText={squadNameValidation && "Need Squad Name"} value={squadName} onChange={(e) => { setSquadName(e.target.value) }} sx={{ width: "80%" }} label="Squad Name" />
                            <Autocomplete
                                multiple
                                onChange={(event, newValue) => {
                                    setSquadVibes(newValue);
                                }}
                                options={vibeList}
                                sx={{ width: "80%" }}
                                renderInput={(params) => <TextField {...params} label="Vibes" />}
                            />
                            <Box sx={{
                                width: "70%",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }} >
                                <Typography variant='body1' sx={{ color: "#708ad4" }}>Budget(in lakhs)</Typography>
                                <Slider
                                    getAriaLabel={() => 'Temperature range'}
                                    value={value}
                                    marks={marks}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                    min={40}
                                    max={150}
                                    step={5}
                                />
                            </Box>
                            {/* <TextField value={memberName} onChange={(e) => { setMemberName(e.target.value) }} sx={{ width: "80%" }} label="Name" /> */}
                            {/* <TextField value={memberMobile} onChange={(e) => { setMemberMobile(e.target.value) }} sx={{ width: "80%" }} label="Mobile" /> */}
                            <Button onClick={onCreate} variant='outlined' sx={{ backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)", color: "white", width: "80%", maxWidth: 200 }}>Create</Button>

                        </Box>
            }
        </Box>
    );
};

export default CreateSquad;