import { Box, Grow, Typography, Zoom } from '@mui/material';
import React from 'react';

const ErrorAnimation = () => {
    return (
        <Box sx={{ background: "white", height: 300, width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <Zoom timeout={2000} in={true}>
                            <img
                                src="/gif/error.gif"
                                alt="Error"
                                style={{ width: 150 }}
                            />
                        </Zoom>
                        <Grow timeout={3000} in={true}><Typography variant='h6' sx={{  color: "red" }}>Error! Please try again.</Typography></Grow>
        </Box>
    );
};

export default ErrorAnimation;