import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Settings } from '@mui/icons-material';
import Preferences from './Preferences';

import { keyframes } from '@mui/system';

const settings = [
                  {label:'Profile',route:'profile'},
                  {label: "Report Bug", route : 'bug-report'},                
                  {label:'Logout',route:'logout'}
                ];

function AppbarTop() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [showPreference, setShowPreference] = React.useState(null);
  const { logout , user } = useAuth0()
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleChangePreferences = (event) => {
    setShowPreference(!showPreference)
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate()
  const handleLogoClick = () => {
    navigate("/")
  }

  const handleNavigateMenu = (route) => {
    if(route === 'logout'){
      logout({ logoutParams: { returnTo: window.location.origin } })
    }else{
      navigate(route)
    }
  }
  const smoothAppear = keyframes`
  to {
    bottom: 20px;
    opacity: 1;
  }`

  return (
    <AppBar sx={{ 
                  zIndex: (theme) => theme.zIndex.drawer + 1, 
                  backgroundColor:"white" 
                }} position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, }}> 
          <img 
            onClick={handleLogoClick}
            src="/logo.png"
            alt="Logo"
            
            style={{ height:40, maxWidth: "60px"}} // Adjust width for mobile
          />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
             
              <IconButton onClick={handleChangePreferences} sx={{ pr: 1 }}>
                <img src="/gif/settings.gif" alt="Settings" style={{height:40, maxWidth: "60px"}} />
              </IconButton>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.name} src={user.picture.length === 0 ? user.picture : "/gif/vlogger.gif"} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.label} onClick={()=>{handleNavigateMenu(setting.route)}}>
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
        </Toolbar>
      </Container>
            {showPreference && <Box sx={{
            animation: `${smoothAppear} 1s ease-out forwards`, // Apply the animation here
            position: 'relative', // Ensure `bottom` works properly
            opacity: 0, // Initial state for animation
            top: '-20px', // Initial starting point,
            display:"flex",
            flexDirection:"column",
            gap:2
          }}> 
          
            <Preferences />
            <Button 
              variant='outlined' 
              onClick={handleChangePreferences}  
              sx={{textTransform:"none", 
              alignSelf:"center",
              width: "50%",
              maxWidth: 150
              }} >
                Close
            </Button>
          </Box>}
    </AppBar>
  );
}
export default AppbarTop;