import { ArrowForwardRounded, ThumbDownAltOutlined, ThumbUpAltOutlined } from '@mui/icons-material';
import { Box, Fab, Snackbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAddWaitingMemberInSquadMutation, useSquadByVibeMutation } from '../../Store/api/apiSlice';
import { useParams } from 'react-router-dom';
import LoadingAnimation from '../../inc/LoadingAnimation';
import { useDispatch, useSelector } from 'react-redux';
import { changeSwipeComponentStatus } from '../../Store/slices/loadingSlice';
import { setSnackbar } from '../../Store/slices/snackbarSlice';
import SquadDetails from '../../inc/SquadDetails';

const SquadSwipe = () => {
    const [squads, setSquads] = useState([]);

    const [selectedSquad, setSelectedSquad] = useState({});
    const buyer = useSelector(state => state.buyer)
    const [squadIndex, setSquadIndex] = useState(0);  // Track the index of the selected squad
    const vibe = useParams();
    const [getSquadsByVibe, { isLoading, data }] = useSquadByVibeMutation();
    const [addMemberInSquadWaiting, { isLoading: memberAddWaiting, isSuccess, isError }] = useAddWaitingMemberInSquadMutation();
    const dispatch = useDispatch()
    const handleInput = (input) => {
        if (input === true) {
            // Move to the next squad in the array when input is true
            const nextIndex = (squadIndex + 1) % squads.length;  // Loop back if it's the last squad
            setSquadIndex(nextIndex);
            setSelectedSquad(squads[nextIndex]);
            // console.log(buyer);

            // Optionally add member to the selected squad's waiting list
            addMemberInSquadWaiting({ squadId: String(squads[squadIndex].squadId), buyer }).unwrap().then(
                (response) => {
                    console.log(response);
                }
            ).catch((error) => {
                console.error("Error adding member:", error);
            });
        } else if (input === false) {
            const nextIndex = (squadIndex + 1) % squads.length;  // Loop back if it's the last squad
            setSquadIndex(nextIndex);
            setSelectedSquad(squads[nextIndex]);
            // console.log(nextIndex);
        }
    };
    useEffect(()=>{
        if(isSuccess){
            dispatch(setSnackbar({
                message : "Request Sent",
                isOpen : true,
                action : "Close"
            }))
        } else if (isError) {
            dispatch(setSnackbar({
                message : "Error Requesting",
                isOpen : true,
                action : "Close"
            }))

        }

    },[memberAddWaiting])
    useEffect(() => {
        if (vibe) {
            getSquadsByVibe({ "vibes": [String(vibe.id)] }).unwrap().then(
                (response) => {
                    setSquads(response);
                    setSelectedSquad(response[0]);  // Set the first squad as default
                    setSquadIndex(0);  // Start with the first squad
                    console.log(response);
                }
            ).catch((error) => {
                console.error("Error fetching squads:", error);
            });
        }
    }, [vibe]);

    useEffect(() => {
        dispatch(changeSwipeComponentStatus(true))
        // Cleanup function to run when the component is unmounted
        return () => {
            dispatch(changeSwipeComponentStatus(false));
        };
    }, [])
    return (
        <Box sx={{ height: "90%", position: "absolute", width: "95%" }}>
            {isLoading ? <LoadingAnimation /> :
            selectedSquad?.squadPitchUrl ?
                <Box sx={{ display: "flex", justifyContent: "center", height: "80%", pt: 1 }}>
                    <video autoPlay muted={false} controls
                        style={{
                            boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
                        }}
                        src={selectedSquad?.squadPitchUrl}>
                    </video>
                </Box> :
                 <Box sx={{ display: "flex", justifyContent: "center", height: "80%", pt: 1 }}>
                 {selectedSquad?.squadId !== null || selectedSquad?.squadId !== undefined ?
                     <SquadDetails id={selectedSquad?.squadId} /> :
                     <Typography>No Squad Selected</Typography>}
             </Box>
            }
            <Box sx={{ display: "flex", justifyContent: 'space-evenly', flexDirection: "row", mt: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                    <Fab onClick={() => { handleInput(true) }} sx={{ color: "white", backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)", }} >
                        <ThumbUpAltOutlined />
                    </Fab>
                    <Typography sx={{ mt: 1 }} variant='caption' >Request</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                    <Fab onClick={() => { handleInput(false) }} sx={{ color: "#708ad4", backgroundImage: "linear-gradient(90deg, #fff 0%, #fff 99%)" }}  >
                        <ArrowForwardRounded />
                    </Fab>

                    <Typography sx={{ mt: 1 }} variant='caption' >Next</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default SquadSwipe;
