import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = [];

// Create the slice
const squadDealSlice = createSlice({
  name: "squad_deals",
  initialState,
  reducers: {
    setSquadDeals(state, action) {
      console.log(action.payload);
        return action.payload; // Replace the state with the new deals array

    },
  },
});

// Export actions
export const { setSquadDeals } = squadDealSlice.actions;

// Export the reducer
export default squadDealSlice.reducer;
