// Store/slices/squadsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        data: {
            message: "",
            isOpen: false,
            action : ""
        },
        // other initial state fields
    },
    reducers: {
        setSnackbar: (state, action) => {
            state.data = action.payload;
        },
        // other reducers
    },
});

export const { setSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
