import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

const LoadingAnimation = () => {
    return (
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
            height:"100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center"
          }}
        >
          <img
            src="/logo.png"
            alt="Squad10"
            style={{ width: 100 }}
          />
          <Box sx={{
            minWidth : 250,
            mt : 2
          }}>
            <LinearProgress sx={{
               backgroundImage: "linear-gradient(90deg, #d0368a 0%, #708ad4 99%)",
               boxShadow: "inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5)", // rgba for white color
               borderRadius: "100px"
              }} />
            <Typography variant='body1' sx={{ textAlign: "center", marginTop: 2, color:"#708ad4" }}>
              Loading Please wait...
            </Typography>
          </Box>
        </Box>
    );
};

export default LoadingAnimation;