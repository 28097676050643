import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { AppBar, Box, Button, Input, Modal, Paper, Rating } from '@mui/material';
import { useSelector } from 'react-redux';
import { Check, PersonAdd } from '@mui/icons-material';
import { useCreateReferralMutation } from '../../Store/api/apiSlice';
import { LoadingButton } from '@mui/lab';
import SuggestPartner from '../../inc/SuggestPartner';

export default function SquadMembers() {
  const squadMembersData = useSelector((state) => state.squad);
  const partnerBusinessData = useSelector(state => state.businessData.data)
  const buyer = useSelector((state) => state.buyer);
  const [squadMembers, setSquadMembers] = React.useState(null);
  const [isInviteModal, setInviteModal] = React.useState(false);
  const [inviteMobile, setInviteMobile] = React.useState(null);
  const [createReferral, { isLoading, error, data, isSuccess }] = useCreateReferralMutation()
  const [openSuggestPartner, setOpenSuggestPartner] = React.useState(false);
  const handleOpenSuggestPartner = () => setOpenSuggestPartner(true);
  const handleCloseSuggestPartner = () => setOpenSuggestPartner(false);
  React.useEffect(() => {
    if (squadMembersData !== null) {
      setSquadMembers(squadMembersData?.members);
    }
  }, [squadMembersData]);

  const handleInvite = () => {
    setInviteModal(true);
  };

  const handleCloseInvite = () => {
    setInviteModal(false);
  };

  const submitInvite = () => {
    const id = String(Date.now());
    const currentTime = new Date().toISOString().slice(0, 19);
    const body = {
      id: id,
      time: currentTime,
      userId: buyer?.userId,
      mobile: inviteMobile,
      name: ""
    }
    createReferral(body).unwrap().then(
      (response) => {
        setInviteMobile("")
      }
    ).catch((err) => {

    })
  }
  return (
    <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', justifyContent: "center" }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',  // Ensure no wrapping of items
          overflowX: 'auto',  // Enable horizontal scrolling
          maxWidth: '100%',  // Ensure the container does not expand beyond the viewport width
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundImage: 'linear-gradient(90deg, #d0368a 0%, #708ad4 99%)',
            boxShadow: 'inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5)',
            borderRadius: '100px',
          },
        }}
      >
        {squadMembers?.length < 9 && (
          <Box
            onClick={handleInvite}
            sx={{
              minWidth: '80px', // Set the same fixed width for the "Invite" button
              py: 2,
              px: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              maxWidth: 50
            }}
          >
            <Avatar
              sx={{
                alignSelf: 'center',
                my: 1,
                backgroundImage: 'linear-gradient(90deg, #d0368a 0%, #708ad4 99%)',
              }}
            >
              <PersonAdd />
            </Avatar>
            <Typography variant="caption">Invite Members</Typography>
          </Box>
        )}
        {partnerBusinessData?.email === null || partnerBusinessData?.email === undefined &&
          <Box
            onClick={handleOpenSuggestPartner}
            sx={{
              minWidth: '80px', // Set the same fixed width for the "Invite" button
              py: 2,
              px: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              maxWidth: 50
            }}
          >
            <Avatar
              sx={{
                alignSelf: 'center',
                my: 1,
                backgroundImage: 'linear-gradient(90deg, #d0368a 0%, #708ad4 99%)',
              }}
            >
              <PersonAdd />
            </Avatar>
            <Typography variant="caption">Suggest Partner</Typography>
          </Box>

        }
        {squadMembers && squadMembers.map((member, index) => (
          <Box
            key={index}
            sx={{
              minWidth: '80px', // Set a fixed width for each item
              py: 2,
              px: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: 50,
            }}
          >
            <Avatar
              sx={{ alignSelf: 'center', my: 1 }}
              alt={member?.name}
              src="/static/images/avatar/1.jpg"
            />
            {member?.isAdmin === false && (
              <Rating
                readOnly
                sx={{
                  color: '#9dccc6',
                  fontSize: '0.8rem',
                  alignSelf: 'center',
                  my: 1,
                }}
                value={member?.ratings}
              />
            )}

          </Box>
        ))}

      </Box>

      <Modal
        open={isInviteModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClose={handleCloseInvite}
      >
        <Paper sx={{ height: '35%', p: 2, textAlign: 'center', borderRadius: 3 }}>
          <img height={80} src="/gif/discount.gif" alt="" />
          <Typography sx={{ py: 1 }}>Invite & Earn</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Input value={inviteMobile} onChange={(e) => { setInviteMobile(e.target.value) }} placeholder="Enter Mobile Number" />
            {error ? <Typography variant='caption' sx={{ color: "red" }}>Error Inviting, Please try again.</Typography> :

              isLoading ? <LoadingButton loading={true} /> :

                isSuccess ? <Typography variant='caption' sx={{ color: "green" }}>Invited to join the squad !</Typography> :
                  <Typography></Typography>
            }
            <Button sx={{
              backgroundImage: 'linear-gradient(90deg, #d0368a 0%, #708ad4 99%)',
              color: "white",
              textTransform: "none",
              width: "80%",
              alignSelf: "center"
            }}
              onClick={submitInvite}>
              Submit
            </Button>
            <Button sx={{width: "80%",alignSelf: "center"}} onClick={handleCloseInvite} variant='outlined' >Cancel</Button>
          </Box>
        </Paper>
      </Modal>
      <Modal
        open={openSuggestPartner}
        onClose={handleCloseSuggestPartner}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", alignItems: "end", justifyContent: "center" }}
      >
        <Box sx={{
          maxWidth: 500,
          width: "100%",
          px: 2,
          py: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "white"
        }}>

          <SuggestPartner />
          <Button onClick={handleCloseSuggestPartner} variant='outlined' >Cancel</Button>
        </Box>
      </Modal>
    </Box>
  );
}
