import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { AddShoppingCart, Check, Tour, Videocam } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Box, Paper } from '@mui/material';
import { useSelector } from 'react-redux';

export default function SquadTimeline() {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [timeline, setTimeline] = React.useState(null);
  const squadTimeline = useSelector((state) => state.squad);

  const iconList = {
    tour: <Tour />,
    video: <Videocam />,
    cart: <AddShoppingCart />,
    check: <Check />,
  };



  const handleTimelineClick = (item) => {
    setSelectedItem(item);
    setIsDialogOpen(true);
  };

  const handleDialogueClose = () => {
    setIsDialogOpen(false);
    setSelectedItem(null);
  };

  React.useEffect(() => {
    if (squadTimeline?.timeline !== undefined) {

      setTimeline(squadTimeline?.timeline);
    }
    // console.log(squadTimeline);

  }, [squadTimeline]);

  return (
    <Paper sx={{ maxWidth: '100%', overflowX: 'auto', px: 0, my: 2 }}>
      <Timeline position="left" sx={{ maxWidth: '100%' }}>
        {timeline &&
          timeline.map((item, index) => (
            <TimelineItem key={index} >
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <Typography variant='caption' >
                  {Intl.DateTimeFormat('en-IN', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true, // 12-hour format
                    day: '2-digit',
                    month: 'short' // Short month format (e.g., "Jan", "Feb")
                  }).format(new Date(item?.time)) || ""}
                </Typography>



              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot>{iconList[item?.icon_id]}</TimelineDot>
                {index !== timeline.length - 1 && <TimelineConnector />}
              </TimelineSeparator>

              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="subtitle1" component="span">
                  {item?.action}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
      {/* <Dialog open={isDialogOpen} onClose={handleDialogueClose}>
        <DialogTitle>{selectedItem?.action}</DialogTitle>
        <DialogContent>
          <DialogContentText>{selectedItem?.short_desc}</DialogContentText>
        </DialogContent>
      </Dialog> */}
    </Paper>
  );
}
