import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetChatsByUserIdQuery } from '../Store/api/apiSlice';
import { setChats } from '../Store/slices/chatDataSlice';
import { useNavigate } from 'react-router-dom';
import LoadingAnimation from '../inc/LoadingAnimation'


const Chats = () => {
    const chatsDataSelector = useSelector(state => state.chatsData.chatsData);
    const buyer = useSelector(state => state.buyer);
    const [chatsData, setChatsData] = useState([])
    const dispatch = useDispatch();
    const [userId, setUserId] = useState(null)

    const { data, error, isLoading } = useGetChatsByUserIdQuery(userId, {
        skip: userId === null || userId === undefined
    });


    useEffect(() => {
        setChatsData(chatsDataSelector)
    }, [chatsDataSelector])
    useEffect(() => {
        // console.log("userIdSelector", buyer?.userId);
        if (buyer?.userId !== null) {
            setUserId(buyer?.userId)
        }
    }, [buyer])
    // useEffect(()=>{
    //     console.log("userId" , userId);
    // },[userId])
    const navigate = useNavigate()
    const openChat = (id) => {
        navigate(`/chats/${id}`)
    };

    useEffect(() => {
        if (data?.chats) {
            dispatch(setChats(data.chats));
        }
        console.log(data?.chats);
        
    }, [isLoading, data]);

    return (
        <Box sx={{ display: "flex", marginHorizontal: 10, marginVertical: 10, justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
            {error ? (
                <Paper sx={{ p: 1, minWidth: "80%", borderRadius: 1, alignSelf: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img height={100} width={100} src="/gif/error.gif" alt="Error" />
                    <Typography sx={{ color: "red", textAlign: "center" }} >{error?.data?.detail || "Error"}</Typography>
                </Paper>
            ) : isLoading ? (
                <LoadingAnimation />
            ) : chatsData?.length === 0 ? (
                <Paper sx={{ p: 1, minWidth: "80%", borderRadius: 1, alignSelf: "center", }}>
                    <Typography sx={{ color: "black", textAlign: "center" }} >   No Active Chats</Typography>
                </Paper>

            ) : <Box sx={{
                minHeight: "100vh",
                width: "100%",
                maxWidth: 500,
                display: "flex",
                flexDirection: "column",
                gap: 1
            }}>
                {
                    chatsData.map((chat) => {
                        return <Paper sx={{ width: "100%", }} onClick={() => openChat(chat.chatId)}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar src='/gif/vlogger.gif' />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={chat?.chatName || chat?.chatId || "" }
                                    secondary={chat?.messages?.[chat.messages.length - 1]?.text || ""}
                                />


                            </ListItem>
                        </Paper>
                    }
                    )
                }
            </Box>
            }
        </Box>

    );
};

export default Chats;
