import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import { Diversity3, EmojiPeople } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import "./welcome.css";
import { AppBar, Autocomplete, Grow, Paper, TextField } from "@mui/material";

const Welcome = () => {
  const { loginWithRedirect, } = useAuth0();
  const [expanded, setExpanded] = React.useState(false);
  const [propertyList, SetPropertyList] = React.useState(["WKD", "AISBCAJS"]);
  const [propertyDealsList, SetPropertyDealsList] = React.useState(["Deal 1"]);
  const [loadAnimation, setLoadAnimation] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoadAnimation(true)
    }, 100)
  }, [])
  const handleSignup = () => {
    loginWithRedirect({
      screen_hint: 'signup'
    });
  };
  return (
    <div className="welcome-container" style={{ height: "100%" }}>
      <Box sx={{ visibility: { md: "hidden" } }}>
        <AppBar sx={{ display: "flex", justifyContent: "center", backgroundColor: "white", p: 1 }}>

          <img
            src="/logo.png"
            alt="Logo"
            style={{ width: 60, maxWidth: "400px", alignSelf: "center" }} // Adjust width for mobile
          />

        </AppBar>
      </Box>

      <Grid
        container
        sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "center" }, alignContent: { xs: "flex-start", md: "center" }, height: { xs: "90%", md: "100%" } }}
      >
        <Grid
          item
          xs={12}
          md={6}
          className="customPadding"
          textAlign="center"
          sx={{
            // Default is "center", but change to "flex-end" for 'sx' screen size
            justifyContent: {
              xs: "center", // Apply vertical alignment to the bottom on small screens
              md: "center",   // Keep the default as center on larger screens
            },
            height: { xs: "60%" },
            paddingX: 2,
            mt: { xs: 13, md: 0 }
          }}
        >
          <Box sx={{
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            gap: 2,
            paddingX: 1,
            scrollbarColor:"white"
          }}>

            <Grow timeout={2000} in={loadAnimation}>
              <Box sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", md: "center", lg:"center", xl:"center" },
                gap: 2,
                gridTemplateColumns: { xs: 'repeat(2, 2fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' },
                mt: 3
              }}>
                {/* <Paper sx={{ justifyContent: "center", display: "flex", flexDirection: "column", p: 2, minWidth: 100 }}>
                  <Box sx={{ height: 50, width: 50, alignSelf: "center" }}><img height={50} src="/gif/family.gif" alt="culture" /></Box>
                  <Typography color="#708ad4" variant='h6'>Culture</Typography>
                  <Typography color="#708ad4" variant='caption'>from hometown</Typography>
                </Paper> */}
                
                <Paper sx={{ justifyContent: "center", display: "flex", flexDirection: "column", p: 2, minWidth: 100 }}>
                  <Box sx={{ height: 100, width: 100, alignSelf: "center" }}><img height={100} src="/gif/hiking.gif" alt="community" /></Box>
                  <Typography color="#708ad4" variant='subtitle2' >Match</Typography>
                  <Typography color="#708ad4" variant='h6'>The Vibe</Typography>
                  <Typography color="#708ad4" variant='caption'>before buying.</Typography>
                </Paper>
                <Paper sx={{ justifyContent: "center", display: "flex", flexDirection: "column", p: 2, minWidth: 150 }}>

                <video
                  autoPlay
                  muted
                  controls
                  style={{ height: 300, width: 150 }}
                  src="https://firebasestorage.googleapis.com/v0/b/seller-squad-10.appspot.com/o/4828601-uhd_2160_4096_25fps.mp4?alt=media&token=d95cd07a-ad7e-4dad-ac5d-db20fb3d3e38">
                </video>
                </Paper>
                <Paper sx={{ justifyContent: "center", display: "flex", flexDirection: "column", p: 2, minWidth: 100 }}>
                  <Box sx={{ height: 100, width: 100, alignSelf: "center" }}><img height={100} src="/gif/discount.gif" alt="discount" /></Box>
                  <Typography color="#708ad4" variant='subtitle2' >Avail</Typography>
                  <Typography color="#708ad4" variant='h6'>Discounts</Typography>
                  <Typography color="#708ad4" variant='caption'>upto 10%</Typography>
                </Paper>
                {/* <Paper sx={{ justifyContent: "center", display: "flex", flexDirection: "column", p: 2, minWidth: 100 }}>
                  <Box sx={{ height: 50, width: 50, alignSelf: "center" }}><img height={50} src="/gif/jeep.gif" alt="area visit" /></Box>
                  <Typography color="#708ad4" variant='h6'>Area Visit</Typography>
                  <Typography color="#708ad4" variant='caption'>with experts</Typography>
                </Paper> */}

                {/* <Paper sx={{ justifyContent: "center", display: "flex", flexDirection: "column", p: 2, minWidth: 100 }}>
                <Box sx={{ height: 50, width: 50, alignSelf: "center" }}><img height={50} src="/gif/incognito.gif" alt="community" /></Box>
                <Typography variant='h6'>No Calls</Typography>
                <Typography variant='caption'>before booking</Typography>
              </Paper> */}
              </Box>
            </Grow>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center", height: { xs: "25%", md: "50%" }, position: { xs: "absolute", md: "relative" }, bottom: { xs: 20 }, alignSelf: "center" }}
        >
          <Paper sx={{ width: { xs: '80%', md: '50%' }, borderRadius: 3, pt: 3, alignItems: "center", alignContent: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "center", display: { xs: "none", md: "flex" } }}>
              <img
                src="/logo.png"
                alt="Logo"
                style={{ width: 100, maxWidth: "400px", alignSelf: "center" }} // Adjust width for mobile
              />
            </Box>
            <Box pb={2} textAlign="center">
              <Button
                sx={{ margin: 1, boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px;", textTransform: "none", color: "#708ad4" }}
                size="large"
                onClick={() => loginWithRedirect()}
              >
                <EmojiPeople /> Login
              </Button>
              <Button
                sx={{ margin: 1, boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 5px;", textTransform: "none", color: "#708ad4" }}
                size="large"
                onClick={() => loginWithRedirect({
                  screen_hint: 'signup'
                })}

              >
                <Diversity3 sx={{ mr: 1 }} /> SignUp
              </Button>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2, alignContent: "center", alignItems: "center" }}>

                <img height={30} src="/gif/india.gif" alt="India" />
              </Box>
            </Box>

          </Paper>
        </Grid>
      </Grid>
      <FloatingWhatsApp
        style={{ height: "auto" }}
        phoneNumber="+917410773108"
        accountName="Sumit"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar="gif/vlogger.gif"
        statusMessage="Typically replies within 20 mins"
      />
    </div>
  );
};

export default Welcome;
